import * as React from "react";
import {StaticQuery, graphql, Link} from "gatsby";
import styled from "styled-components";
import ContactCardMain from "./contactCardMain";
import {
  px,
  colors,
  stage,
  smallRegular,
  WPAcfPostsProps,
  RowDiv,
  staticPageMap,
  staticPages,
  breakpoints,
  microRegular,
} from "../../models";
import {trackFooterLinkClick} from "../../util/tracking";

import logoBLI from "../../images/contactMainBLI.svg";
import logoDFL from "../../images/contactMainDFL.svg";

const Cell = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  background-color: ${colors.grayUltraLight};

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 64px 0;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    padding: 0;
  }
`;

const BottomRow = styled.div`
  min-height: unset;
  max-height: unset;

  display: flex;
  flex-direction: column;

  ${Cell} {
    &:nth-child(1) {
      margin-bottom: 2px;
    }
  }

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    flex-direction: row;
    min-height: 160px;
    max-height: 200px;

    ${Cell} {
      &:nth-child(1) {
        margin-bottom: 0;
      }
    }
  }
`;

const FooterRow = styled.div`
  width: 100%;
  background-color: ${colors.black};
`;
const FooterRowInner = styled.div`
  width: 100%;
  max-height: unset;
  min-height: unset;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: 100%;
    padding: 0 20px;
    max-height: 60px;
    min-height: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.outerWidth)};
    margin: 0 auto;
    padding: 0;
  }
`;
const FooterLinks = styled(RowDiv)`
  ${microRegular};
  height: 70px;
  justify-content: center;
  background-color: ${colors.grayDark};

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    ${smallRegular};
    background-color: ${colors.transparent};
    height: unset;
  }
`;
const StaticPageLinks = styled(props => <Link {...props} />)`
  color: ${colors.white};

  &:hover {
    text-decoration: underline;
  }
`;
const Separator = styled.div`
  margin: 0 10px;
  color: ${colors.white};
`;
const FooterText = styled(RowDiv)`
  ${microRegular};
  color: ${colors.white};
  height: 70px;
  justify-content: center;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    height: unset;
    ${smallRegular};
    color: ${colors.white};
  }
`;

interface QueryProps {
  wpPost: WPAcfPostsProps;
}
const ContactListComp = () => {
  return (
    <StaticQuery
      query={graphql`
      query {
        wpPost(databaseId: {eq: 1}) {
            contacts {
              mainBli {
                name
                email
                phone
                website
              }
              mainDfl {
                name
                email
                phone
                website
              }
            }
          }
      }
      `}
      render={(data: QueryProps) => {
        const contactMainBLI = data.wpPost.contacts.mainBli[0];
        const contactMainDFL = data.wpPost.contacts.mainDfl[0];

        const legalNoticeLabel = staticPageMap.get(staticPages.legalNotice).label;
        const privacyPolicyLabel = staticPageMap.get(staticPages.privacyPolicy).label;
        const imprintLabel = staticPageMap.get(staticPages.imprint).label;
        return (
          <div>
            <BottomRow>
              <Cell>
                <ContactCardMain logo={logoDFL} {...contactMainDFL} />
              </Cell>

              <Cell>
                <ContactCardMain logo={logoBLI} {...contactMainBLI} />
              </Cell>
            </BottomRow>

            <FooterRow>
              <FooterRowInner>
                <FooterLinks>
                  <StaticPageLinks
                    to={`/${staticPageMap.get(staticPages.legalNotice).slug}`}
                    onClick={() => trackFooterLinkClick(legalNoticeLabel)}
                  >
                    {legalNoticeLabel}
                  </StaticPageLinks>

                  <Separator>|</Separator>

                  <StaticPageLinks
                    to={`/${staticPageMap.get(staticPages.privacyPolicy).slug}`}
                    onClick={() => trackFooterLinkClick(privacyPolicyLabel)}
                  >
                    {privacyPolicyLabel}
                  </StaticPageLinks>

                  <Separator>|</Separator>

                  <StaticPageLinks
                    to={`/${staticPageMap.get(staticPages.imprint).slug}`}
                    onClick={() => trackFooterLinkClick(imprintLabel)}
                  >
                    {imprintLabel}
                  </StaticPageLinks>
                </FooterLinks>

                <FooterText>© 2025 DFL Deutsche Fußball Liga e.V.</FooterText>
              </FooterRowInner>
            </FooterRow>
          </div>
        );
      }}
    />
  );
};

export default ContactListComp;
